<h1 mat-dialog-title style="text-align:center;margin-top:-5%;">{{ title }}</h1>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div>
      <div class="form-group">
        <label>Billing Status :</label>
        <select style="height: 4vh;" name="billingStatus" formControlName="billingStatus">
          <option value="Select Option" selected>Select Option</option>
          <option value="Review in Process">Review in Process</option>
          <option value="Bill in Process">Bill in Process</option>
          <option value="Billed">Billed</option>
          <option value="Research">Research</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Only for Record Creation">Only for Record Creation</option>
        </select>
      </div>
      <!-- <div *ngIf="formGroup.controls['billingStatus'].invalid && formGroup.controls['billingStatus'].touched" class="error">Billing Status is required</div> -->
      <br />
      <div class="form-group" *ngIf="showDeliveryDate">
        <label>Delivery Date :</label>
        <input type="date" id="deliveryDate" formControlName="deliveryDate" class="form-control">
      </div>
      <!-- <div *ngIf="formGroup.controls['deliveryDate'].invalid && formGroup.controls['deliveryDate'].touched " class="error">Delivery Date is required</div> -->
      <div *ngIf="isInvalidYear()" class="error">Delivery Date is Invalid</div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="align-end">
  <button mat-button mat-dialog-close (click)="onCancel()" class="primary ms-button" [ngStyle]="{'color': 'white', 'left': '-29%', 'margin-top': isToBeBilled ? '-23%' : '0'}">Cancel</button>
  <button mat-button [disabled]="isUpdate" (click)="onUpdate()" class="primary ms-button" [ngStyle]="{'color': 'white', 'left': '-26%', 'margin-top': isToBeBilled ? '-23%' : '0'}">Update</button>
</mat-dialog-actions>
