import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { adminRequestDashboard} from './_interface/request-dashboard.model';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RequestFormComponent } from '../request-form/request-form.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdminRequestEditFormComponent } from '../admin-request-edit-form/admin-request-edit-form.component';
import { UserService } from '../auth.details';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { SpinnerServiceService } from '../services/spinner-service.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { timeInterval, timeout } from 'rxjs';
import { CommonHelperService } from '../services/common-helper.service';
import { WindowRefService } from '../services/window.service';


interface ColumnDef<T> {
  columnDef: string;
  header: string;
  cell: (element: T) => string;
}

@Component({
  selector: 'app-request-dashboard',
  templateUrl: './request-dashboard.component.html',
  styleUrl: './request-dashboard.component.css'
})

export class RequestDashboardComponent implements OnInit, AfterViewInit {
  columns!: ColumnDef<any>[];
  isAdmin!: boolean;
  searchText: string = '';
  dataSource: any;
  isAllFiltersNotRemoved: boolean = false;
  exportDataSource: Array<any> = [];
  displayedColumns: any;
  firstAppliedFilterColumn:any=[];
  filterShownData:any=[];
  isLoading = false;
  currentPageIndex = 0;
  toDateValue: any;
  fromDateValue: any;
  enableButton: boolean = false;
  yearsList:any=[];
  selectedYear:any="";
  filters: { [key: string]: any[] } = {};
  isGridDataAvailable:boolean=true;
  initialLoadData:any=[];
  inValidDaterange: boolean = false;
  shownorecords:boolean=false;
  invalidDate: boolean = false;
  constructor(private http: HttpClient, private dialog: MatDialog, private appInsightsService: AppInsightsService,
    private datePipe: DatePipe, private userService: UserService,private spinnerService:SpinnerServiceService,private commonHelperService:CommonHelperService, private windowService: WindowRefService) { }

  adminDisplayedColumns = ['ID', 'Created', 'BillingStatus', 'ReportPeriodEndDate', 'RequestStatus', 'RequestDate', 'IDNumber', 'ReportLength',
    'VerifyServiceOrganization', 'SARReportType', 'ThirdPreparer', 'ServiceOrganizationDeloitteClient', 'ContentType', 'SARAuthoritativeGuidance',
    'NumberOfRequestsReceivedForPriorPeriodReview', 'AllottedDate', 'AllottedToOld', 'Application', 'BusinessContact', 'CommentsForWorkflow',
    'Cost', 'CreatedByName', 'DateTimeOfLastAction', 'DeliveryDate', 'ExclusiveToClient', 'Existing', 'FirstPreparer', 'FolderChildCount',
    'LocationsCoveredInReport', 'ModifiedByName', 'NeedByDate', 'NumberOfReviewComments', 'OpinionDate', 'ReportPeriod', 'ReportPeriodStartDate',
    'RequestPriority', 'RequestorDepartment', 'RequestorTitle', 'RequestorsName', 'RequestorsNameLotusNotes', 'SecondPreparer', 'ServiceAuditor',
    'ServiceSector', 'SRCD', 'TargetAudiences', 'Title', 'TypeOfAudit', 'TypeOfOpinion', 'XYZCode', 'WBS', 'ServiceOrganization', 'WBSClientName',
    'RequestorEmail', 'RequestorEmail2', 'MFEngagementName', 'MFPartnerName', 'MFWBSCode', 'TestDate', 'FRCD', 'FRRD', 'Modified', 'EmphasisOfMatter', 'Publish','PreviousPeriodReviewAvailable','AuditSigningPartner'];
  adminColumnDisplayNames = this.adminDisplayedColumns.reduce((acc, column) => {
    acc[column] = acc[column] || column.split(/(?=[A-Z])/).join(' ');
    return acc;
  }, {
    'ID': 'ID',
    'IDNumber': 'ID Number',
    'SARReportType': 'SAR Report Type',
    'SARAuthoritativeGuidance': 'SAR Authoritative Guidance',
    'SRCD': 'SRCD',
    'XYZCode': 'XYZ Code',
    'WBS': 'WBS',
    'MFEngagementName': 'MF Engagement Name',
    'MFPartnerName': 'MF Partner Name',
    'MFWBSCode': 'MF WBS Code',
    'FRCD': 'FRCD',
    'FRRD': 'FRRD'
  } as Record<string, string>);

  adminColumns = this.adminDisplayedColumns.map(column => ({
    columnDef: column,
    header: this.adminColumnDisplayNames[column] || column.split(/(?=[A-Z])/).join(' '),
    cell: (element: any) => `${element[column]}`
  }));


  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;

  ngOnInit() {
    this.appInsightsService.logPageView('RequestDashboard');
    this.spinnerService.showSpinner();// show spinner
    this.selectedYear = this.commonHelperService.defaultYearLoader();
    this.yearsList = this.commonHelperService.generateArrayOfYears();
    this.isAdmin = this.userService.isAdmin;
    this.displayedColumns = this.adminDisplayedColumns;
    this.columns = this.adminColumns
    this.dataSource = new MatTableDataSource<adminRequestDashboard>();
    this.getAdminRequestData(1, 50, "ID", true);
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");           


      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "Request Dashboard", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }
     
      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
     
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilter();
    if (this.paginator) {
      this.paginator.page.subscribe((pageEvent: PageEvent) => {
        this.currentPageIndex = pageEvent.pageIndex;
       // this.getAdminRequestData(pageEvent.pageIndex + 1, pageEvent.pageSize, "ID", true);
     this.dataSource.data= this.initialLoadData;//this.commonHelperService.loadPageWiseData(pageEvent.pageIndex + 1, pageEvent.pageSize,this.initialLoadData);
       setTimeout(() => {
        this.paginator.pageIndex = pageEvent.pageIndex + 1 - 1;
        this.paginator.pageSize = pageEvent.pageSize;
        this.paginator.length = this.dataSource.data.length;
      }, 0);
      });
    }
  }
  ValidateDates(startDate:any,endDate:any):boolean{
    let isInValid=false;
    if(startDate==null&&endDate==null){
      isInValid= false;
    }
    else{
      if(startDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."||
        endDate?.validationMessage=="Please enter a valid value. The field is incomplete or has an invalid date."){
          isInValid= true;
      }
    }
    return isInValid;
  
  }
  onYearChange(){
    this.spinnerService.showSpinner();
    this.resetFiltersBck();
    //this.getAdminRequestData(1, 50, "ID", true);
  }
  //DATASOURCE
  public getAdminRequestData = (pageNumber: number, pageSize: number, sortBy: string, sortDescending: boolean) => {
    this.isLoading = true;
    
    let params = new HttpParams();
    params = params.append('searchText', this.selectedYear.toString());
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy', sortBy);
    params = params.append('sortDescending', sortDescending.toString());

    this.http.get<{ Data: adminRequestDashboard[], Total: number }>(environment.baseUrl + '/RequestList/GetAdminRequestList', { params: params }).subscribe(
      response => {
        if(response && response.Data && response.Data.length >0)
        {
            //DeliveryDate is a datetime field in DB and comes with default time which
            //we don't want to display in UI
            response.Data.forEach(x=>{
              x.DeliveryDate = (x.DeliveryDate == null ? '' : x.DeliveryDate.split('T')[0])
            });
        }
        this.dataSource.data = response.Data;
        if(response.Data.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
        this.initialLoadData=response.Data;
        let defaultData={
          key:"",
          value:response.Data
        }
        this.filterShownData.push(defaultData);
        //this.dataSource.data = this.dataSource.data.map((obj: adminRequestDashboard) => {
        //  if (obj.BillingStatus == null || obj.BillingStatus == "null" || obj.BillingStatus == "NULL") {
        //    obj.BillingStatus = "";
        //  }
        //  if (obj.RequestStatus == null || obj.RequestStatus == "null" || obj.RequestStatus == "NULL") {
        //    obj.RequestStatus = "";
        //  }
        //  if (obj.IDNumber == null || obj.IDNumber == "null" || obj.IDNumber == "NULL") {
        //    obj.IDNumber = "";
        //  }
        //  if (obj.ReportLength == null || obj.ReportLength == "null" || obj.ReportLength == "NULL") {
        //    obj.ReportLength="" ;
        //  }
        //  if (obj.VerifyServiceOrganization == null || obj.VerifyServiceOrganization == "null" || obj.VerifyServiceOrganization == "NULL") {
        //    obj.VerifyServiceOrganization = "";
        //  }
        //  if (obj.ThirdPreparer == null || obj.ThirdPreparer == "null" || obj.ThirdPreparer == "NULL") {
        //    obj.VerifyServiceOrganization = "";
        //  }
        //  if (obj.ServiceOrganizationDeloitteClient == null || obj.ServiceOrganizationDeloitteClient == "null" || obj.ServiceOrganizationDeloitteClient == "NULL") {
        //    obj.ServiceOrganizationDeloitteClient = "";
        //  }
        //  if (obj.ContentType == null || obj.ContentType == "null" || obj.ContentType == "NULL") {
        //    obj.ContentType = "";
        //  }
        //  if (obj.NumberOfRequestsReceivedForPriorPeriodReview == null || obj.NumberOfRequestsReceivedForPriorPeriodReview == "null" || obj.NumberOfRequestsReceivedForPriorPeriodReview == "NULL") {
        //    obj.NumberOfRequestsReceivedForPriorPeriodReview = "";
        //  }
        //  if (obj.AllottedDate == null || obj.AllottedDate == "null" || obj.AllottedDate == "NULL") {
        //    obj.AllottedDate = "";
        //  }
        //  if (obj.AllottedToOld == null || obj.AllottedToOld == "null" || obj.AllottedToOld == "NULL") {
        //    obj.AllottedToOld = "";
        //  }
        //  if (obj.Application == null || obj.Application == "null" || obj.Application == "NULL") {
        //    obj.Application = "";
        //  }
        //  if (obj.BusinessContact == null || obj.BusinessContact == "null" || obj.BusinessContact == "NULL") {
        //    obj.BusinessContact = "";
        //  }
        //  if (obj.CommentsForWorkflow == null || obj.CommentsForWorkflow == "null" || obj.CommentsForWorkflow == "NULL") {
        //    obj.CommentsForWorkflow = "";
        //  }
        //  if (obj.Cost == null || obj.Cost == "null" || obj.Cost == "NULL") {
        //    obj.Cost = "";
        //  }
        //  if (obj.DateTimeOfLastAction == null || obj.DateTimeOfLastAction == "null" || obj.DateTimeOfLastAction == "NULL") {
        //    obj.DateTimeOfLastAction = "";
        //  }
        //  if (obj.DeliveryDate == null || obj.DeliveryDate == "null" || obj.DeliveryDate == "NULL") {
        //    obj.DeliveryDate = "";
        //  }
        //  if (obj.ExclusiveToClient == null || obj.ExclusiveToClient == "null" || obj.ExclusiveToClient == "NULL") {
        //    obj.ExclusiveToClient = "";
        //  }
        //  if (obj.Existing == null || obj.Existing == "null" || obj.Existing == "NULL") {
        //    obj.Existing = "";
        //  }
        //  if (obj.FirstPreparer == null || obj.FirstPreparer == "null" || obj.FirstPreparer == "NULL") {
        //    obj.FirstPreparer = "";
        //  }
        //  if (obj.FolderChildCount == null || obj.FolderChildCount == "null" || obj.FolderChildCount == "NULL") {
        //    obj.FolderChildCount = "";
        //  }
        //  if (obj.LocationsCoveredInReport == null || obj.LocationsCoveredInReport == "null" || obj.LocationsCoveredInReport == "NULL") {
        //    obj.LocationsCoveredInReport = "";
        //  }
        //  if (obj.ModifiedBy == null || obj.ModifiedBy == "null" || obj.ModifiedBy == "NULL") {
        //    obj.ModifiedBy = "";
        //  }
        //  if (obj.NeedByDate == null || obj.NeedByDate == "null" || obj.NeedByDate == "NULL") {
        //    obj.NeedByDate = "";
        //  } else {
        //    obj.NeedByDate = obj.NeedByDate.substring(0, 10);
        //  }
        //  if (obj.NumberOfReviewComments == null || obj.NumberOfReviewComments == "null" || obj.NumberOfReviewComments == "NULL") {
        //    obj.NumberOfReviewComments = "";
        //  }
        //  if (obj.OpinionDate == null || obj.OpinionDate == "null" || obj.OpinionDate == "NULL") {
        //    obj.OpinionDate = "";
        //  }
        //  if (obj.ReportPeriod == null || obj.ReportPeriod == "null" || obj.ReportPeriod == "NULL") {
        //    obj.ReportPeriod = "";
        //  }
        //  if (obj.ReportPeriodStartDate == null || obj.ReportPeriodStartDate == "null" || obj.ReportPeriodStartDate == "NULL") {
        //    obj.ReportPeriodStartDate = "";
        //  }
        //  if (obj.RequestPriority == null || obj.RequestPriority == "null" || obj.RequestPriority == "NULL") {
        //    obj.RequestPriority = "";
        //  }
        //  if (obj.RequestorDepartment == null || obj.RequestorDepartment == "null" || obj.RequestorDepartment == "NULL") {
        //    obj.RequestorDepartment = "";
        //  }
        //  if (obj.RequestorTitle == null || obj.RequestorTitle == "null" || obj.RequestorTitle == "NULL") {
        //    obj.RequestorTitle = "";
        //  }
        //  if (obj.RequestorsName == null || obj.RequestorsName == "null" || obj.RequestorsName == "NULL") {
        //    obj.RequestorsName = "";
        //  }
        //  if (obj.RequestorsNameLotusNotes == null || obj.RequestorsNameLotusNotes == "null" || obj.RequestorsNameLotusNotes == "NULL") {
        //    obj.RequestorsNameLotusNotes = "";
        //  }
        //  if (obj.SecondPreparer == null || obj.SecondPreparer == "null" || obj.SecondPreparer == "NULL") {
        //    obj.SecondPreparer = "";
        //  }
        //  if (obj.ServiceAuditor == null || obj.ServiceAuditor == "null" || obj.ServiceAuditor == "NULL") {
        //    obj.ServiceAuditor = "";
        //  }
        //  if (obj.ServiceSector == null || obj.ServiceSector == "null" || obj.ServiceSector == "NULL") {
        //    obj.ServiceSector = "";
        //  }
        //  if (obj.SRCD == null || obj.SRCD == "null" || obj.SRCD == "NULL") {
        //    obj.SRCD = "";
        //  }
        //  if (obj.TargetAudiences == null || obj.TargetAudiences == "null" || obj.TargetAudiences == "NULL") {
        //    obj.TargetAudiences = "";
        //  }

        //  if (obj.Title == null || obj.Title == "null" || obj.Title == "NULL") {
        //    obj.Title = "";
        //  }
        //  if (obj.TypeOfAudit == null || obj.TypeOfAudit == "null" || obj.TypeOfAudit == "NULL") {
        //    obj.TypeOfAudit = "";
        //  }
        //  if (obj.TypeOfOpinion == null || obj.TypeOfOpinion == "null" || obj.TypeOfOpinion == "NULL") {
        //    obj.TypeOfOpinion = "";
        //  }
        //  if (obj.XYZCode == null || obj.XYZCode == "null" || obj.XYZCode == "NULL") {
        //    obj.XYZCode = "";
        //  }
        //  if (obj.WBS == null || obj.WBS == "null" || obj.WBS == "NULL") {
        //    obj.WBS = "";
        //  }
        //  if (obj.ServiceOrganization == null || obj.ServiceOrganization == "null" || obj.ServiceOrganization == "NULL") {
        //    obj.ServiceOrganization = "";
        //  }
        //  if (obj.WBSClientName == null || obj.WBSClientName == "null" || obj.WBSClientName == "NULL") {
        //    obj.WBSClientName = "";
        //  }
        //  if (obj.RequestorEmail == null || obj.RequestorEmail == "null" || obj.RequestorEmail == "NULL") {
        //    obj.RequestorEmail = "";
        //  }
        //  if (obj.RequestorEmail2 == null || obj.RequestorEmail2 == "null" || obj.RequestorEmail2 == "NULL") {
        //    obj.RequestorEmail2 = "";
        //  }
        //  if (obj.MFEngagementName == null || obj.MFEngagementName == "null" || obj.MFEngagementName == "NULL") {
        //    obj.MFEngagementName = "";
        //  }
        //  if (obj.MFPartnerName == null || obj.MFPartnerName == "null" || obj.MFPartnerName == "NULL") {
        //    obj.MFPartnerName = "";
        //  }
        //  if (obj.MFWBSCode == null || obj.MFWBSCode == "null" || obj.MFWBSCode == "NULL") {
        //    obj.MFWBSCode = "";
        //  }
        //  if (obj.TestDate == null || obj.TestDate == "null" || obj.TestDate == "NULL") {
        //    obj.TestDate = "";
        //  }
        //  if (obj.FRCD == null || obj.FRCD == "null" || obj.FRCD == "NULL") {
        //    obj.FRCD = "";
        //  }
        //  if (obj.FRRD == null || obj.FRRD == "null" || obj.FRRD == "NULL") {
        //    obj.FRRD = "";
        //  }
        //  if (obj.Modified == null || obj.Modified == "null" || obj.Modified == "NULL") {
        //    obj.Modified = "";
        //  } else {
        //    obj.Modified = obj.Modified.substring(0, 10);
        //  }
        //  if (obj.EmphasisOfMatter == null || obj.EmphasisOfMatter == "null" || obj.EmphasisOfMatter == "NULL") {
        //    obj.EmphasisOfMatter = "";
        //  }
        //  if (obj.ThirdPreparer == null || obj.ThirdPreparer == "null" || obj.ThirdPreparer == "NULL") {
        //    obj.ThirdPreparer = "";
        //  }
        //  if (obj.RequestDate == null || obj.RequestDate == "null" || obj.RequestDate == "NULL") {
        //    obj.RequestDate = "";

        //  } else {
        //    obj.RequestDate = obj.RequestDate.substring(0, 10);
        //  }
        //  if (obj.SARAuthoritativeGuidance == null || obj.SARAuthoritativeGuidance == "null" || obj.SARAuthoritativeGuidance == "NULL") {
        //    obj.SARAuthoritativeGuidance = "";
        //  }
        //  // obj.Created = this.datePipe.transform(obj.Created, 'yyyy-MM-dd');
        //  if (obj.Created == null || obj.Created == "null" || obj.Created == "NULL") {
        //    obj.Created = "";

        //  } else {
        //    obj.Created = obj.Created.substring(0, 10);
        //  }
        ////  obj.Created = obj.Created.substring(0, 10);
         

        //  return obj
        //})

       // this.dataSource.data.foreach
        this.isLoading = false;
        this.spinnerService.hideSpinner();// hide spinner
        setTimeout(() => {
          this.paginator.pageIndex = pageNumber - 1;
          this.paginator.pageSize = pageSize;
          this.paginator.length = response.Total;
        }, 0);
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while fetching data!', error);
      }
    );
  }
  onDateChange(event: any): void {
    const fromDate = this.fromDateValue ? new Date(this.fromDateValue) : null;
    const toDate = this.toDateValue ? new Date(this.toDateValue) : null;
    const minDate = new Date('1990-01-01');
    const maxDate = new Date('2050-12-31');

    if (!fromDate && !toDate) {
      // If both dates are cleared
      this.inValidDaterange = false;
      this.invalidDate = false;
      this.enableButton = false;
    } else if (toDate && this.isValidDate(toDate)) {
      if (fromDate) {
        if (this.isValidDate(fromDate)) {
          if (toDate >= fromDate && toDate <= maxDate && fromDate >= minDate) {
            this.inValidDaterange = false;
            this.invalidDate = false;
            this.enableButton = true; // enable button if date range is valid
          } else if (toDate < fromDate) {
            this.inValidDaterange = true;
            this.invalidDate = false;
            this.enableButton = false; // disable button if date range is invalid
          } else {
            this.inValidDaterange = false;
            this.invalidDate = true; // show error message if date is out of range
            this.enableButton = false; // disable button if date is out of range
          }
        } else {
          this.invalidDate = true; // show error message if fromDate is invalid
          this.inValidDaterange = false;
          this.enableButton = false; // disable button if fromDate is invalid
        }
      } else {
        this.inValidDaterange = false;
        this.invalidDate = false;
        this.enableButton = true; // enable button if only toDate is valid
      }
    } else {
      this.invalidDate = true; // show error message if toDate is invalid
      this.inValidDaterange = false;
      this.enableButton = false; // disable button if toDate is invalid
    }

    // Show error message only if the date is out of the min and max range
    if ((fromDate && (fromDate < minDate || fromDate > maxDate)) || (toDate && (toDate < minDate || toDate > maxDate))) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
    }
    console.log(event);
  }

  isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1000;
  }

  public exportToExcel()
 {
   var dat1 = this.toDateValue;
   var dat2 = this.fromDateValue;
    this.isLoading = true;
    this.spinnerService.showSpinner();// show spinner
    let params = new HttpParams();

   this.http.get<Array<adminRequestDashboard>>(environment.baseUrl + '/RequestList/GetExportList?toDate='+dat1+'&fromDate='+dat2, { params: params }).pipe(timeout(1200000)).subscribe(
     response => {
       this.shownorecords = false;
        if(response!=null && response!=undefined && response.length > 0)
        {
          this.isLoading = false;
        this.spinnerService.hideSpinner();// hide spinner

        var jsonData:Array<any> = [];
        jsonData = response.map(doc => Object.values(doc));
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
        
          worksheet['A1'].v = "Service Organization";
          worksheet['B1'].v = "Application";
          worksheet['C1'].v = "Report Period Start Date";
          worksheet['D1'].v = "Report Period End Date";
          worksheet['E1'].v = "Request Status";
          worksheet['F1'].v = "Billing Status";
          worksheet['G1'].v = "ID Number"; 
          worksheet['H1'].v = "ID";
          worksheet['I1'].v = "WBS";
          worksheet['J1'].v = "WBS Client Name";
          worksheet['K1'].v = "Requestors Name";
          worksheet['L1'].v = "Requestor Email";
          worksheet['M1'].v = "Requestor Title";
          worksheet['N1'].v = "Requestor Department";
          worksheet['O1'].v = "Business Contact";
          worksheet['P1'].v = "Audit Signing Partner";
          worksheet['Q1'].v = "First Preparer";
          worksheet['R1'].v = "Second Preparer";
          worksheet['S1'].v = "Service Auditor";
          worksheet['T1'].v = "SAR Authoritative Guidance";
          worksheet['U1'].v = "Content Type";
          worksheet['V1'].v = "Type Of Audit";
          worksheet['W1'].v = "Type Of Opinion";
          worksheet['X1'].v = "Emphasis Of Matter";
          worksheet['Y1'].v = "Exclusive To Client";
          worksheet['Z1'].v = "FRRD";
          worksheet['AA1'].v = "FRCD";
          worksheet['AB1'].v = "SRCD";
          worksheet['AC1'].v = "Need By Date";
          worksheet['AD1'].v = "Locations Covered In Report";
          worksheet['AE1'].v = "Previous Period Review W/P Available";
          worksheet['AF1'].v = "Report Length";
          worksheet['AG1'].v = "Request Date";
          worksheet['AH1'].v = "Number Of Requests Received For Prior Period Review";
          worksheet['AI1'].v = "Allotted Date";
          worksheet['AJ1'].v = "Comments For Workflow";
          worksheet['AK1'].v = "Cost";
          worksheet['AL1'].v = "Created";
          worksheet['AM1'].v = "Created By";
          worksheet['AN1'].v = "Date Time Of Last Action";
          worksheet['AO1'].v = "Delivery Date";
          worksheet['AP1'].v = "Existing";
          worksheet['AQ1'].v = "Modified";
          worksheet['AR1'].v = "Modified By";
          worksheet['AS1'].v = "Opinion Date";
          worksheet['AT1'].v = "Service Organization Deloitte Client";
          worksheet['AU1'].v = "Service Sector";
          worksheet['AV1'].v = "Title";
          worksheet['AW1'].v = "Verify Service Organization";
          worksheet['AX1'].v = "Third Preparer";
          worksheet['AY1'].v = "SAR Report Type";
          worksheet['AZ1'].v = "MF Engagement Name";
          worksheet['BA1'].v = "MF Partner Name";
          worksheet['BB1'].v = "MF WBS Code";
          worksheet['BC1'].v = "Publish?";
          worksheet['BD1'].v = "Requestor Email2";
          worksheet['BE1'].v = "Allotted To Old";
          worksheet['BF1'].v = "Folder Child Count";
          worksheet['BG1'].v = "Report Period";
          worksheet['BH1'].v = "Request Priority";
          worksheet['BI1'].v = "Target Audiences";
          worksheet['BJ1'].v = "XYZ Code";
          worksheet['BK1'].v = "Test Date";    

          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', compression:true });
        const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
          FileSaver.saveAs(data, 'Request_Review_List_' + new Date().getTime() + ".xlsx");
          //this.enableButton = false;
          this.inValidDaterange = false;
        }
        else
        {
           //put alert message here.
          this.isLoading = false;
          this.spinnerService.hideSpinner();
          this.shownorecords=true;

        }
        
      },
      error => {
        this.appInsightsService.logException(error);
        this.appInsightsService.logEvent('There was an error while exporting excel data!', error);
        console.log('export error data:', error);
        this.enableButton = false;
        this.inValidDaterange = false;
        this.shownorecords = false;
      }
    );
  }
  //POPUP
  openDialog() {
    this.dialog.open(RequestFormComponent, {
      panelClass: 'custom-dialog',
      width: '800px',
      height: '550px',
      disableClose: true
    });
  }

  //EDIT ITEM
  editItem(element: any) {
    const dialogRef = this.dialog.open(AdminRequestEditFormComponent, {
      data: {
        ...element
      }, panelClass: 'custom-dialog', height: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  //FILTER
  getUniqueColumnValues(columnName: string): string[] {
    // Get the filtered data
    let filteredData:any;
    filteredData=this.dataSource.filteredData;
 if(this.firstAppliedFilterColumn.length>0){
   this.filterShownData.forEach((element: any) => {
     if (element.key == columnName) {
       filteredData = element.value;
     }
   })
}

    const columnValues = filteredData.map((item: any) => {
      if (item[columnName] instanceof Date) {
        // Format the date
        const date = new Date(item[columnName]);
        const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        // Check if formattedDate is not null before using it
        if (formattedDate) {
          return this.commonHelperService.ValidateFilterData(formattedDate);
        }
      }
      return this.commonHelperService.ValidateFilterData(item[columnName]);
    });
    return [...new Set(columnValues as string[])];
  }


  selectedFilters: any = {};

  tempFilters: any = {};

  filterOptions: { [key: string]: string[] } = {};
  ValidateIsFilterExists(){
    this.displayedColumns.forEach((column:any)=>{
               
      if(this.tempFilters[column]?.length>0){this.isAllFiltersNotRemoved=true;}
      if(this.tempFilters[column]?.length==0){delete this.tempFilters[column];}
    })
  }

  getfiltercolour(column:string) {
    const hasIdKey = Object.prototype.hasOwnProperty.call(this.tempFilters, column);
    return hasIdKey;
  }
  applyFilter(event: any, option: any, column: string) {
    // Convert option to a string
    this.isAllFiltersNotRemoved=false;
    let columnWiseFilterOptions:any={};
    let filteredValueAlreadyExists=false;
    const optionStr = option==null||option==undefined||option=="null"||option=="NULL"?"":String(option);
    if(this.firstAppliedFilterColumn.length==0){
      this.firstAppliedFilterColumn.push(column);
      columnWiseFilterOptions = {
        key: column,
        value: this.dataSource.filteredData
      }
      this.filterShownData.push(columnWiseFilterOptions);
    }
      else{
        this.firstAppliedFilterColumn.forEach((element:any) => {
          if(element==column){
            filteredValueAlreadyExists=true;
          }
        });
        if(!filteredValueAlreadyExists){this.firstAppliedFilterColumn.push(column);
          columnWiseFilterOptions={ 
            key:column,
            value:this.dataSource.filteredData
           }
              this.filterShownData.push(columnWiseFilterOptions);
        }
      }
    // If the checkbox is checked, add the option to the temporary filters
    if (event.checked) {
      this.commonHelperService.LoadFilterAppliedFields(event?.source?.id,column);
      this.isAllFiltersNotRemoved=true;
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.loadFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      if (this.tempFilters[column]) {
        this.tempFilters[column].push(optionStr ? optionStr.toLowerCase() : '');
      } else {
        this.tempFilters[column] = [optionStr ? optionStr.toLowerCase() : ''];
      }
    }
    } else {
      this.commonHelperService.RemoveFilterAppliedFields(event?.source?.id,column);
      // If the checkbox is not checked, remove the option from the temporary filters
      if(optionStr==""){
        this.tempFilters=this.commonHelperService.removeFiltersEmptyDataValues(this.tempFilters,column);}
      else{
      const index = this.tempFilters[column].indexOf(optionStr ? optionStr.toLowerCase() : '');
      if (index >= 0) {
        this.tempFilters[column].splice(index, 1);
      }
    }
      this.ValidateIsFilterExists();
    }
    if(this.isAllFiltersNotRemoved){this.applyFilters();}else{this.clearFilter("");}
  }


  customFilterBck(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return Object.keys(searchTerms).every(name => {
        if (searchTerms[name].length === 0) {
          return true;  // If no filters are set on this column, then return true
        }
        let dataStr = '';
        if (data[name]) {
          // Convert data[name] to a string
          dataStr = String(data[name]).trim().toLowerCase();
        }
        return searchTerms[name].includes(dataStr);
      });
    };
    return filterFunction;
  }
  customFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const { searchText, filters } = JSON.parse(filter);
      const globalMatch = !searchText || Object.values(data).some(value =>
        String(value).toLowerCase().includes(searchText)
      );
  
      const columnMatch = Object.keys(filters).every(column => {
        return filters[column].includes(String(data[column]).toLowerCase());
      });
  
      return globalMatch && columnMatch;
    };
  }
  applyFilters() {
    this.ValidateIsFilterExists();
    // Copy tempFilters to selectedFilters
    this.selectedFilters = JSON.parse(JSON.stringify(this.tempFilters));
    // Trigger the filter
   // this.dataSource.filter = JSON.stringify(this.selectedFilters);
   if(!this.isAllFiltersNotRemoved){
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: {} });
   }else{
    this.dataSource.filter = JSON.stringify({ searchText: this.searchText, filters: this.selectedFilters });
   }
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}

    // Update the filter options for all columns
    this.columns.forEach(column => {
      const uniqueValues = this.getUniqueColumnValues(column.columnDef);
      // Update the filter options for the column
      this.updateFilterOptions(column.columnDef, uniqueValues);
    });
  }

  updateFilterOptions(column: string, uniqueValues: string[]) {
    this.filterOptions[column] = uniqueValues;
  }


  clearFilter(column: string) {
    this.isAllFiltersNotRemoved=false;
    // Remove the filters for the column
    delete this.selectedFilters[column];
    delete this.tempFilters[column];

    this.ValidateIsFilterExists();
    // Reset the checkboxes
    this.clearCheckboxes(column);
    // this.checkboxes.forEach((checkbox:any) => {
    //   this.commonHelperService.filterAppliedFields.forEach((element:any) => {
    //     if(element.value==column&&checkbox.id==element.key||column==""){
    //       checkbox.checked = false;
    //     }
    //   });
    // });

    // Reset the filter predicate to the original one
   // this.dataSource.filterPredicate = this.customFilter();
    if(this.isAllFiltersNotRemoved||this.searchText!=""){
      this.applyFilters();
    }
    else{
      this.firstAppliedFilterColumn=[];
 this.filterShownData=[];
    // Trigger the filter
    this.dataSource.filter = "";

    // Fetch the data without any filters
    this.getAdminRequestData(this.currentPageIndex + 1, this.paginator.pageSize, "ID", true);
    }
  }
  clearCheckboxes(column:any) {
    this.checkboxes.forEach((checkbox: MatCheckbox) => {
      this.commonHelperService.filterAppliedFields.forEach((element:any) => {
        if(element.value==column&&checkbox.id==element.key||column==""){
          checkbox.checked = false;
        }
      });
    });
  }
  applyGlobalFilter(filterValue: string) {
    // Show the spinner
    this.spinnerService.showSpinner();
    // delay to ensure spinner is visible
    setTimeout(() => {
    this.searchText = filterValue.trim().toLowerCase();
      this.applyFilters();
      // Hide the spinner after data is loaded
      this.spinnerService.hideSpinner();
    }, 500); //delay
  }

  resetFilters() {
    this.firstAppliedFilterColumn=[];
    this.filterShownData=[];
    this.searchText = '';
    this.filters = {};
    this.selectedFilters = {};
    this.tempFilters = {};
    this.applyFilters();
    this.clearCheckboxes("");
  }
  applyGlobalFilterBck(filterValue: any) {
    // Reset the filter predicate to the default
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Transform the data into a lowercase string of key value pairs.
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        // Check if data[key] is not null before concatenating
        return currentTerm + (data[key] ? data[key] : '') + '◬';
      }, '').toLowerCase();

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    };

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length==0){this.isGridDataAvailable=false;}else{this.isGridDataAvailable=true;}
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFiltersBck() {
    this.firstAppliedFilterColumn=[];
 this.filterShownData=[];
    // Clear the filter value
    this.dataSource.filter = '';
    this.searchText = '';

    // Clear the selected and temporary filters
    this.selectedFilters = {};
    this.tempFilters = {};

    // Reset the checkboxes
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    // Reset the filter predicate to the original one
    this.dataSource.filterPredicate = this.customFilter();

    this.sort.active = '';
    this.sort.direction = '';

    // Fetch the data without any filters
    this.getAdminRequestData(this.currentPageIndex + 1, this.paginator.pageSize, "ID", true);

  }

}
