import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../auth.details';
import { environment } from '../../environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { Observable, of, from } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '../auth.service';
import { WbsModalComponent } from '../wbs-modal/wbs-modal.component';
import { WBSRecords } from '../wbs-modal/wbs-records.interface';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { SpinnerServiceService } from '../services/spinner-service.service';
import { AlertPopupComponent } from '../notify-popup/notify-popup.component';
import { CommonHelperService } from '../services/common-helper.service';
import { WindowRefService } from '../services/window.service';

@Component({
  selector: 'app-existing-request-form',
  templateUrl: './existing-request-form.component.html',
  styleUrl: './existing-request-form.component.css'
})

export class ExistingRequestFormComponent implements OnInit {
  form!: FormGroup;
  id!: string;
  idNumber!: string;
  sarReportType!: string;
  dateObjectValid:any={needByDate:false,requestDate:false};
  isAdmin!: boolean;
  showNoResults:boolean = false;
  users: { displayName: string, jobTitle: string, mail: string, department: string }[] = [];
  filteredUsersBussinessContact: Observable<{ displayName: string, jobTitle: string,mail:string }[]> = of([]);
  filteredUsersRequestors: Observable<{ displayName: string, jobTitle: string, mail: string, department: string }[]> = of([]);
  filteredAuditSigningPartner: Observable<{ displayName: string, jobTitle: string, mail: string }[]> = of([]);
  checkClickedWithoutValue = false;
  isGlobalwbscode: boolean = false;
  validateWBSCode: boolean = false;
  mfengagementname: boolean = false;
  mfengagementpartnername: boolean = false;
  mfwbscode: boolean = false;
  _isRequestorNameValid: boolean = false;
  _isBusinessNameValid: boolean = false;
  _isAuditNameValid: boolean = false;
  autoCompleteUserSelected:any={
    requestorsUserSelected:false,
    businessContactUserSelected:false,
    auditSigningUserSelected:false
  };
  clientName: string | undefined;

  constructor(private router: Router, private route: ActivatedRoute,
    private appInsightsService: AppInsightsService,
    private http: HttpClient, private userService: UserService, private authService: AuthService, private dialog: MatDialog,
    private spinnerService:SpinnerServiceService,private commonHelperService:CommonHelperService,private windowService: WindowRefService) { }

  ngOnInit() {
    this.appInsightsService.logPageView('ExistingRequestForm');

    this.isAdmin = this.userService.isAdmin;
    this.id = this.route.snapshot.paramMap.get('id') ?? '';
    this.idNumber = this.route.snapshot.paramMap.get('idNumber') ?? '';
    if (this.idNumber == null || this.idNumber=="null") {
      this.idNumber = "";
    }
    this.sarReportType = this.route.snapshot.paramMap.get('sarReportType') ?? '';
    this._isRequestorNameValid = false;
    this._isBusinessNameValid = false;
    this._isAuditNameValid = false;
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;
    this.form = new FormGroup({
      idNumber: new FormControl(this.idNumber, Validators.required),
      // businessContact: new FormControl('', Validators.required, Validators.pattern('.*\S.*')),
      businessContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')])),
      needByDate: new FormControl(formattedDate, Validators.required),
      requestorsName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')])),
      typeOfAudit: new FormControl('Financial Audit', Validators.required),
      title: new FormControl('View Details', Validators.required),
      requestDate: new FormControl(formattedDate, Validators.required),
      WBS: new FormControl('', Validators.required),
      sarReportType: new FormControl(this.sarReportType),
      mfEngagementName: new FormControl(''),
      mfPartnerName: new FormControl(''),
      mfWBSCode: new FormControl(''),
      requestoremail: new FormControl(''),
      businessContactEmail: new FormControl(''),
      auditSigningPartnerEmail: new FormControl(''),
      requestorTitle: new FormControl(''),
      requestorDepartment: new FormControl(''),
      AuditSigningPartner: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('.*\S.*')])),
    });

    this.authService.getUsers().then((users: any[]) => {
      this.users = users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail, department: user.department }));
      this.filteredUsersBussinessContact = this.form.controls['businessContact'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredUsersRequestors = this.form.controls['requestorsName'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );
      this.filteredAuditSigningPartner = this.form.controls['AuditSigningPartner'].valueChanges.pipe(
        startWith(''),
        switchMap((value: string | null) => this._filter(value || ''))
      );

    })
    if (this.windowService.nativeWindow._satellite) {
      //alert("got the satellite obj");
      let analyticsDataLayer =
      {
        'pageInfo':
        {
          'pageName': "Existing Request Form", // Page Name
          'userID': this.userService.userEmail.split("@")[0], // User Alias, no domain
          'applicationName': "SAR" // Name of application
        }
      }

      this.windowService.nativeWindow.analyticsDataLayer = analyticsDataLayer;
      this.windowService.nativeWindow._satellite.track("pageView");
    }

    // Add value change listeners for MF fields
    this.form.controls['mfEngagementName'].valueChanges.subscribe(value => {
      this.mfengagementname = !value;
    });

    this.form.controls['mfPartnerName'].valueChanges.subscribe(value => {
      this.mfengagementpartnername = !value;
    });

    this.form.controls['mfWBSCode'].valueChanges.subscribe(value => {
      this.mfwbscode = !value;
    });
  }

  private _filter(value: string): Observable<any[]> {
    this.showNoResults = false;
    if (value.length >= 3) { // Only search if the user has typed at least 3 characters
      return from(this.authService.searchUsers(value)).pipe(
        switchMap((users: any[]) => {
          if (users.length == 0) {
            this.showNoResults = true;
          }
          return of(users.map((user: any) => ({ displayName: user.displayName, jobTitle: user.jobTitle, mail: user.mail, department: user.department })));
        })
      );
    } else {
      // If the user hasn't typed enough characters, return an empty array
      return of([]);
    }
  }
  setUserSelectionDynamic(field:string,action:string){
    if(field=="requestorsName"){
      this.autoCompleteUserSelected.requestorsUserSelected=action=="remove"?false:true;
    }
    else if(field=="businessContact"){
      this.autoCompleteUserSelected.businessContactUserSelected=action=="remove"?false:true;
    }
    else if(field=="AuditSigningPartner"){
      this.autoCompleteUserSelected.auditSigningUserSelected=action=="remove"?false:true;
    }
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent, field: string): void {
    //const displayName = event.option.value;
    //this.form.controls[field as keyof typeof this.form.controls].setValue(displayName);
    const selectedUser = event.option.value; // This will be the entire user object
    console.log('Selected User:', selectedUser); // Log the selected user object
    if (selectedUser) {
      this.setUserSelectionDynamic(field,"add");
      this.form.controls[field as keyof typeof this.form.controls].setValue(selectedUser.displayName);
      this.form.controls[field as keyof typeof this.form.controls].setErrors(null);
      //this.form.controls[field as keyof typeof this.form.controls].setValue(selectedUser.mail);
      if (field ==='requestorsName') {
        this.form.controls['requestoremail'].setValue(selectedUser.mail);
        this.form.controls['requestorTitle'].setValue(selectedUser.jobTitle);
        this.form.controls['requestorDepartment'].setValue(selectedUser.department);
        this._isRequestorNameValid = true;
      }
      else if (field === 'businessContact') {
        this.form.controls['businessContactEmail'].setValue(selectedUser.mail);
        this._isBusinessNameValid = true;
      } else if (field === 'AuditSigningPartner') {
        this.form.controls['auditSigningPartnerEmail'].setValue(selectedUser.mail);
        this._isAuditNameValid = true;
      }
    } else {
      console.warn('User not found for selected value:', event.option.value); // Log a warning if user is not found
    }

  }
  removeSelectedUser(field:string){
    this.setUserSelectionDynamic(field,"remove");
    if (field === 'requestorsName') {
      this.form.controls['requestoremail'].setValue(null);
      this._isRequestorNameValid = false;
    }
    else if (field === 'businessContact') {
      this.form.controls['businessContactEmail'].setValue(null);
      this._isBusinessNameValid = false;
    } else if (field === 'AuditSigningPartner') {
      this.form.controls['auditSigningPartnerEmail'].setValue(null);
      this._isAuditNameValid = false;
    }
    this.form.controls[field as keyof typeof this.form.controls].setValue(null);
  }

  onCancelClick(): void {
    this.router.navigate(['/userRequestDashboard']);
  }

  saveChanges(): void {
    this.validateWBSCode=false;
    if (this.form.controls['WBS'].value.trim() == "") {
      this.form.controls['WBS'].setErrors({required:true});
    }

    if (this.form.controls['requestoremail'].value == null) {
      this.form.controls['requestorsName'].setValue(null);
      this.form.controls['requestorsName'].setErrors({ required: true });
    }

    if (this.isGlobalwbscode) {
      if (this.form.controls['mfEngagementName'].value == "" || this.form.controls['mfPartnerName'].value == "" || this.form.controls['mfWBSCode'].value == "") {
        this.form.markAllAsTouched();
        this.mfengagementname = !this.form.controls['mfEngagementName'].value;
        this.mfengagementpartnername = !this.form.controls['mfPartnerName'].value;
        this.mfwbscode = !this.form.controls['mfWBSCode'].value;
        return;
      }
    } 
    if (!this.form.controls['requestoremail'].value) {
      this.form.get('requestorsName')?.setErrors({ required: true });
    }
    if (!this.form.controls['businessContactEmail'].value) {
      this.form.get('businessContact')?.setErrors({ required: true });
    }
    if (!this.form.controls['auditSigningPartnerEmail'].value) {
      this.form.get('AuditSigningPartner')?.setErrors({ required: true });
    }
    let isDatesValid=this.isDatesValidOnForm();
    this.spinnerService.showSpinner();
    if (this.form.valid&&isDatesValid) {
      // Submit the form
      const formData = {
        IDNumber: this.form.value.idNumber,
        BusinessContact: this.form.value.businessContact,
        NeedByDate: this.form.value.needByDate,
        RequestorName: this.form.value.requestorsName,
        TypeOfAudit: this.form.value.typeOfAudit,
        Title: this.form.value.title,
        RequestDate: this.form.value.requestDate,
        SARReportType: this.form.value.sarReportType,
        WBS: this.form.value.WBS,
        MFEngagementName: this.form.value.mfEngagementName,
        MFPartnerName: this.form.value.mfPartnerName,
        MFWBSCode: this.form.value.mfWBSCode,
        RequestorEmail: this.form.value.requestoremail,
        RequestorTitle: this.form.value.requestorTitle,
        RequestorDepartment: this.form.value.requestorDepartment,
        AuditSigningPartner: this.form.value.AuditSigningPartner,
        CreatedByName: this.userService.userName,
        AuditSigningPartnerEmail: this.form.value.auditSigningPartnerEmail,
        BusinessContactEmail: this.form.value.businessContactEmail,
        ClientName: this.clientName
      };
     
        this.updateExistingRequestReviewList(formData, Number(this.id), this.userService.userEmail).subscribe({
          next: response => {
            this.appInsightsService.logEvent("Request Form Save:", { Response: response });
            //alert('Data saved');
            this.spinnerService.hideSpinner(); // Hide spinner after data is saved
            this.router.navigate(['/userRequestDashboard']);
          },
          error: error => {
            this.appInsightsService.logException(error);
            this.openAlertDialog('Warning', 'Failed to save data');
            this.spinnerService.hideSpinner(); // Hide spinner in case of error
          }
        });     
    } else {
      // Mark the form as touched to trigger the error message
      this.form.markAllAsTouched();
      if (this.form.controls['mfEngagementName'].value == "") {

        this.mfengagementname = true;
      }
      if (this.form.controls['mfPartnerName'].value == "") {

        this.mfengagementpartnername = true;
      }
      if (this.form.controls['mfWBSCode'].value == "") {

        this.mfwbscode = true;
      }
      if (this.form.controls['requestoremail'].value) {
        this.form.get('requestorsName')?.markAsUntouched();
      }
      if (this.form.controls['businessContactEmail'].value) {
        this.form.get('businessContact')?.markAsUntouched();
      }
      if (this.form.controls['auditSigningPartnerEmail'].value) {
        this.form.get('AuditSigningPartner')?.markAsUntouched();
      }
      this.spinnerService.hideSpinner();
      this.spinnerService.hideSpinner(); // Hide spinner if form is invalid
    }
  }
 openAlertDialog(title: string, message: string,msglength:string= "2"): void {
    this.dialog.open(AlertPopupComponent, {
      data: { title, message },
      width: '40%' ,
      height: msglength == "2" ? '30%' : '48%',
      disableClose: true
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(WbsModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const wbsControl = this.form.get('WBS');
        if (wbsControl) {
          wbsControl.setValue((result as WBSRecords).WBSElement);
        }

        // extract ClientName from result and storing it
        this.clientName = (result as WBSRecords).ClientName;
      }
    });
  }
  onMouseLeave() {
    const wbsControl = this.form.get('WBS');
    if (wbsControl && wbsControl.value && wbsControl.value.trim() != '') {
      
      this.isWbsExists(wbsControl.value);
    }
  }
  isInvalidDate(selectedDate: any,controlName:string): any {
    const isValidDate=this.commonHelperService.ValidateDates(selectedDate);
    if(controlName=="requestDate"){
      this.dateObjectValid.requestDate=isValidDate;
    }
    else if(controlName=="needByDate"){
      this.dateObjectValid.needByDate=isValidDate;
    }
    return isValidDate;
  }
  isDatesValidOnForm(){
    return !this.dateObjectValid.requestDate&&!this.dateObjectValid.needByDate;
  }
  searchWBSRecords() {
    // If the WBS field is empty
    if (!this.form.controls['WBS'].value) {
      this.checkClickedWithoutValue = true;
    }
    else {
      this.checkClickedWithoutValue = false;
      const wbsControl = this.form.get('WBS');
      if (wbsControl && wbsControl.value) {
        this.isWbsExists(wbsControl.value);
      }
    }
  }
  isWbsExists(wbscode: string) {
    this.spinnerService.showSpinner();
    this.http.get(environment.baseUrl + '/GlobalMFWBSCodes/IsWBSCodeExist?WBSCode=' + wbscode, { responseType: 'text' })
      .subscribe(
        response => {
          console.log(response);
          this.spinnerService.hideSpinner();
          if (response != null) {
            this.form.controls['WBS'].setErrors(null);
            if (response.toLowerCase() === "global wbs code already exists") {
              this.isGlobalwbscode = true;
              this.validateWBSCode = false;

            }
          } else {
            this.validateWBSCode = true;
            this.form.controls['WBS'].setErrors({ 'incorrect':true });
          }
        },
        error => {
          // Handle the error here
          console.error(error);
          this.spinnerService.hideSpinner();
        }
      );


  }

  public updateExistingRequestReviewList = (requestParameters: any, ID: number, createdByEmail: string) => {
    let params = new HttpParams();
    params = params.append('createdByEmail', createdByEmail.toString());
    params = params.append('ID', ID);

    return this.http.post(environment.baseUrl + '/RequestList/CreateExistingRequestReviewList', requestParameters, { params: params }).pipe(
      tap({
        next: response => {
          this.appInsightsService.logEvent('Existing Request Form Save:', { Response: response });
        },
        error: error => {
          alert(error.error);
          this.appInsightsService.logException(error);
          this.appInsightsService.logEvent('There was an error while fetching data!', error);
        }
      })
    );
  }
getCorssMarkStyles(field:string):any{
  let selectedData=this.form.controls[field as keyof typeof this.form.controls].value?.toString();
  selectedData=selectedData==null||selectedData==undefined?"":selectedData;
    return this.commonHelperService.getCorssMarkStyles(selectedData);
  }
}
